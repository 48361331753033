<template>
  <div class="rounded border">
    <div class="whitespace-pre-wrap break-words p-8" :class="{ 'rtl text-right': ['ar', 'he'].includes(language) }">
      <div v-if="currentIndex === 0 && initialMessage && partnerName" class="mb-4 text-sm font-semibold">This post was suggested by {{ partnerName }}</div>
      <span v-if="error" class="flex items-center space-x-2.5 text-red-600">
        <Icon icon="times" />
        <span>Oops! Something went wrong!</span>
      </span>
      <span v-else-if="!currentChat.message" class="block">
        <Icon icon="spinner-third" prefix="fad" spin />
      </span>
      <span v-else>{{ currentChat.message }}</span>
    </div>

    <div class="flex divide-x border-t text-sm">
      <div class="flex shrink-0 items-center text-supplement">
        <button @click="previousChat" class="py-4 pl-5 pr-4" :class="currentIndex === 0 ? 'cursor-not-allowed text-gray-300' : `hover:text-paragraph`">
          <Icon icon="chevron-left" />
        </button>
        <span>{{ currentIndex + 1 }} / {{ chats.length }}</span>
        <button @click="nextChat" class="py-4 pl-4 pr-5" :class="currentIndex === lastIndex ? 'cursor-not-allowed text-gray-300' : 'hover:text-paragraph'">
          <Icon icon="chevron-right" />
        </button>
      </div>
      <div class="flex grow items-center justify-end">
        <div v-if="status === 'pending'" class="px-5 py-4">
          <Icon icon="spinner-third" prefix="fad" spin />
        </div>
        <button v-else @click="newChat" class="space-x-3 p-5 py-4">
          <span>{{ $t("ai_regenerate") }}</span>
          <Icon icon="refresh" prefix="far" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type ChatAiProps = {
  modelValue: string | null | undefined;
  endpoint: string;
  language?: string;
  partnerName?: string;
  initialMessage?: string;
  replace?: Record<string, string>;
};

const props = withDefaults(defineProps<ChatAiProps>(), {
  language: "en",
});

const emit = defineEmits(["update:modelValue", "status"]);

const { backendUrl } = useRuntimeConfig().public;

const chats = ref<{ message: string }[]>([]);
const currentIndex = ref(0);
const lastIndex = computed(() => chats.value.length - 1);
const currentChat = computed(() => chats.value[currentIndex.value]);

const url = computed(() => new URL(props.endpoint, backendUrl).toString());
const { status, data, error, refresh } = useEventSource(url, !props.initialMessage);
chats.value.push({ message: props.initialMessage ? props.initialMessage : data.value });

// Handle status changes
const unwatchStatus = watch(status, () => emit("status", status.value));
onUnmounted(unwatchStatus);

// Handle data changes
const unwatchData = watch(data, () => {
  const currentChat = chats.value[currentIndex.value];
  currentChat.message = data.value.replace(/%NL%/g, "\n");

  if (props.replace) {
    Object.entries(props.replace).forEach(([key, value]) => {
      currentChat.message = currentChat.message.replace(key, value);
    });
  }

  emit("update:modelValue", currentChat.message);
});

onUnmounted(unwatchData);

if (props.initialMessage) {
  status.value = "success";
}

// New Chat
const newChat = () => {
  chats.value.push({ message: "" });
  currentIndex.value = chats.value.length - 1;
  refresh();
};

// Chat Navigation
const previousChat = () => {
  if (currentIndex.value > 0) {
    --currentIndex.value;
    emit("update:modelValue", currentChat.value.message);
  }
};

const nextChat = () => {
  if (currentIndex.value < lastIndex.value) {
    ++currentIndex.value;
    emit("update:modelValue", currentChat.value.message);
  }
};
</script>
